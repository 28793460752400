"use client";
import Link from "next/link";
import React from "react";
import { useAuth } from "../../context/user";
import { randomBytes } from "crypto";

export default function HomeCTA() {
  const user = useAuth();
  const noteId = randomBytes(16).toString("hex");

  return (
    <div className="flex flex-col items-center gap-12">
      {user ? (
        <Link href={`/note/${noteId}`} legacyBehavior>
          <a className="mt-10 inline-block bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-4 px-8 rounded-lg text-xl transition duration-300">
            Create a Note
          </a>
        </Link>
      ) : (
        <Link href={`/note/${noteId}`} legacyBehavior>
          <a className="mt-10 inline-block bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-4 px-8 rounded-lg text-xl transition duration-300">
            Try it - It's Free
          </a>
        </Link>
      )}
      <a
        href="https://www.producthunt.com/posts/noter-2?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-noter&#0045;2"
        target="_blank"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=464141&theme=light"
          alt="Noter - Noter&#0058;&#0032;Simplify&#0032;Your&#0032;Note&#0045;Taking&#0032;with&#0032;Elegance&#0032;and&#0032;Ease&#0046; | Product Hunt"
          style={{ width: "250px", height: "54px" }}
          width="250"
          height="54"
        />
      </a>
    </div>
  );
}
